
  import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {goodsInfoHttp} from '@/api/goods'
  //外部点击
  import '~/utils/directive'
  import {dealGamefilter, gamefilterHttp, gameListHttp, goodsTypeListHttp} from "~/api/comm";
  @Component({
    name:"logoSearch",
    components: {
      // newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class logoSearch extends Vue {
    @Prop({default:'1'}) logoType?: any
    @Prop({default:'默认页'}) logoText?: any
    @Prop({default:''}) bgColor?: any
    // @Prop({default:()=>{}}) goodsInfo_?: any
    @Mutation private SET_GAMEFILTER_DATA!: any;
    @State(state => state.web.gamefilterArray) gamefilterArray!: any;
    @Mutation private SET_HOTGAMES_DATA!: any;
    @State(state => state.web.hotGamesArray) hotGamesArray!: any;
    @Mutation private SET_GAMELIST_DATA!: any;
    @State(state => state.web.gameListArray) gameListArray!: any;

    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any
    public getGoodsListUrl:any
    public openUrl:any

    private isClickOption : boolean = false // 是否点击选项，如果点击，则不执行handleClose
    private selectGameTxt : string = '选择游戏' // 选中游戏
    private selectAreaTxt : string = '选择大区' // 选中大区
    private selectServerTxt : string = '选择服务器' // 选中服务器
    private selectGoodsTypeTxt : string = '商品类型'
    private selectGameIndex : any = -1 // 选中游戏id
    private selectAreaIndex : any = -1 // 选中大区id
    private selectServerIndex : any = -1 // 选中服务器id
    private selectGoodsTypeId : any = -1 // 选中商品类型
    private seletype : Number = 0 // 显示下拉框的位置
    private wordsIndex: string = 'hot' // 字母默认选中hot
    private words : Array<string> = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"] // 字母
    private gameList : Array<any> = [] // 游戏列表
    private areaList : Array<any> = [] // 大区列表
    private serverList : Array<any> = [] // 服务器列表
    private queryParm : any = {rec:1}
    private searchQueryParm : any = {gameId:'',areaId:'',serverId:'',keyWord:'',goodsType:''}
    private allGoodsTypeList : Array<any> = [] // 所有商品类型列表
    private goodsTypeList : Array<any> = [] // 当前游戏商品类型列表
    private selectedGameType : any = -1   // 当前选择游戏大类 端游手游
    private searchGameInput : any = ''    // 弹出层中选择游戏的搜索框的值
    private isShowSearchGameModal : boolean = false
    private gameSearchResult : any = []
    private historyGameArr : any = []

    private webSiteUrl:string=''

    @Watch('searchGameInput')
    listenSearchGameInput(newVal:any) {
      if(this.gameListArray.length == 0) {
        return
      }
      if(newVal != '') {
        this.isShowSearchGameModal = true
        this.gameSearchResult = this.gameListArray.filter((v:any) => v.name.includes(newVal))
      } else {
        this.isShowSearchGameModal = false
      }
    }
    async mounted(){
      console.log(this.hotGamesArray,'wecjkljklklkl');

      let webUrl:string=''
      if(window.location.origin) {
        webUrl = window.location.origin
      } else {
        webUrl = window.location.protocol + '//' + window.location.host
      }

      if(this.gameListArray.length <30) {   //vuex中没有所有游戏就去请求
        await this.getAllGameList()
      }

      if(!!window.localStorage.getItem('historyGame')) {
        this.historyGameArr = JSON.parse(String(window.localStorage.getItem('historyGame')))
      } else {
        this.historyGameArr = []
      }

      this.webSiteUrl=webUrl
      setTimeout(async ()=>{
        if (this.hotGamesArray && this.hotGamesArray.length>0) {
          this.gameList=this.hotGamesArray
        } else {
          await this.getGameList(true)
        }

        let gameId:any=this.$route.query.gid?this.$route.query.gid:this.$route.query.gameId

        // 请求全部商品类型列表
        // const tempGoodsTypeListHttp:any = await goodsTypeListHttp()
        // this.allGoodsTypeList = tempGoodsTypeListHttp.data.data.map((v:any) => {return {goodsTypeId:v.goodsTypeId,goodsTypeName:v.goodsTypeName}})
        // console.log(this.allGoodsTypeList,'akljdflwefioou')

        this.$nextTick(async () => {
          if (gameId) { //列表页需要默认当前页面的游戏
            // console.log(this.$route.query.gameId,this.gameListArray,'======gameId=======她她她她她她===gameListArray=======')
            if (this.gameListArray && this.gameListArray.length>0) { //存在全部游戏缓存
              let gameFlag:any=this.gameListArray.find((v:any)=>v.id==gameId)
              if (gameFlag) { //如果在全部游戏列表找到该游戏
                this.searchQueryParm.gameId=gameFlag.id
                this.selectGameTxt=gameFlag.name
                this.getAreaList(this.searchQueryParm.gameId)
              } else { //全部游戏缓存不存在当前游戏，则拉取所有游戏
                await this.getAllGameList()
                let gameFlag2:any=this.gameListArray.find((v:any)=>v.id==gameId)
                if (gameFlag2) { //如果在全部游戏列表找到该游戏
                  this.searchQueryParm.gameId=gameFlag2.id
                  this.selectGameTxt=gameFlag2.name
                  this.getAreaList(this.searchQueryParm.gameId)
                }
              }
            } else {
              await this.getAllGameList()
              let gameFlag3:any=this.gameListArray.find((v:any)=>v.id==gameId)
              if (gameFlag3) { //如果在全部游戏列表找到该游戏
                this.searchQueryParm.gameId=gameFlag3.id
                this.selectGameTxt=gameFlag3.name
                this.getAreaList(this.searchQueryParm.gameId)
              }
            }
          } else {
            // if (this.$store.state.web.distributorsObject.distBaseConf && this.$store.state.web.distributorsObject.distBaseConf.teGameId) {
            //   for (let k in this.gameList) {
            //     console.log(k,'=====kk======')
            //     if (this.gameList[k].id==this.$store.state.web.distributorsObject.distBaseConf.teGameId){
            //       console.log(this.gameList[k])
            //       this.selectGameIndex=k
            //       this.selectGameTxt=this.gameList[k].name
            //       this.isClickOption = true;
            //       this.searchQueryParm.gameId=this.gameList[k].id
            //       return
            //     }
            //   }
            // }
                }
        })
      },50)
    }
    get getIsHotGame() {       // 判断是否为热门游戏
      return (item:any) => {
        if(this.hotGamesArray && this.hotGamesArray.length > 0) {
          console.log(this.hotGamesArray,item,'echnkljkl');
          let flag = this.hotGamesArray.findIndex((v:any) => v.id==item.id)
          console.log(flag,'echnkljkl');

          if(flag > -1) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    }

    @Emit()
    onblank(item:any){
      console.log(item,'+++++++++++++++++++++++++++++')
      //this.$router.push('/login');

      this.$router.push({
        path:`/wares/?gt=1&gid=${item.id}`,
      })

     


    }

    @Emit()
    getImgUrl(url:string){
      if (url && url.indexOf('http')>-1) {
        return url
      } else {
        return `https://oss1.hk1686.com/${url}`
      }
    }

    @Emit()
    // 获取筛选游戏列表
    async getGameList(flag:boolean=false){
      // console.log(this.$store.state.web.distributorsObject.distBaseConf.recGameStr,'=======recGameStr=========')
      let realQueryParm = JSON.parse(JSON.stringify(this.queryParm))
      if(realQueryParm && realQueryParm.type == -1) {
        delete realQueryParm.type
      }
      try {
        let {data}=await gameListHttp({params:realQueryParm})
        if (this.errorToastFn(data)) {
          if (flag) {
            this.gameList=[]
            let hotGameArray:any=[]
            if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
              hotGameArray=this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(',')
              for (let i:number=0;i<hotGameArray.length;i++) {
                let gameFlag:any=data.data.find((res:any)=>res.id==hotGameArray[i])
                if (gameFlag) {
                  this.gameList.push(gameFlag)
                }
              }
              if (!this.hotGamesArray || (this.hotGamesArray && this.hotGamesArray.length<this.gameList.length)) {
                this.SET_HOTGAMES_DATA(this.gameList)
              }
            }
          } else {
            this.gameList=data.data
            // this.SET_GAMELIST_DATA(this.gameList)
          }
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
        this.gameList=[]
      }
    }
    @Emit()
    //拉取所有游戏列表
    async getAllGameList(){
      try {
        let {data}=await gameListHttp()
        if (this.errorToastFn(data)) {
          this.SET_GAMELIST_DATA(data.data)
        }
      } catch (error) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    // 获取游戏大区服务器列表 id:游戏id
    async getAreaList(id:number){
      if (id) {
        console.log(this.gamefilterArray)
        if (!this.gamefilterArray[id]) { //不存在游戏配置
          try {
            let{data}=await gamefilterHttp({params:{gameId:id}})
            if (this.errorToastFn(data)) {
              this.gamefilterArray[id]=dealGamefilter(data.data)
              this.SET_GAMEFILTER_DATA(this.gamefilterArray)
              if (this.gamefilterArray[id]){
                //获取大区
                let areafilter :any=Object.values(this.gamefilterArray[id]).find((v:any)=>v.fieldKey=="areaId")
                this.areaList=areafilter.options
              }
            }
          } catch (error) {
            this.eventNotifyTost('提示', '网络超时', 'warning')
          }
        } else { //存在游戏配置
          //获取大区
          let areafilter :any=Object.values(this.gamefilterArray[id]).find((v:any)=>v.fieldKey=="areaId")
          if (areafilter) {
            this.areaList=areafilter.options
          }
        }
      }
    }
    handleOpenPage(id:string){
      this.openUrl(`/wares/?gt=1&gid=${id}`)
      setTimeout(()=>{
        window.location.reload()
      },0)
    }
    
    // 选择商品类型
    selectGoodsType(index:number,name:string,id:number) {
      this.seletype = -1
      this.selectGoodsTypeId = id
      this.selectGoodsTypeTxt = name
      this.searchQueryParm.goodsType = id
    }
    @Emit()
    // 点击label显示下拉框
    async clickLabel(v:number,type:any) {
      console.log(type,'======vvvvv======')
      this.seletype = v;
      if (v==1) {
        this.wordsIndex=type
        if (type=='hot') {
          if(this.selectedGameType>-1) {
            if(this.selectedGameType == 1) {
              this.gameList=this.hotGamesArray.filter((value:any) => value.gameType==1)
            } else if(this.selectedGameType == 2) {
              this.gameList=this.hotGamesArray.filter((value:any) => value.gameType==2)
            }
          } else {
            this.gameList=this.hotGamesArray
          }
        } else {
          delete this.queryParm.rec
          this.queryParm['initial']=type
          this.getGameList(false)
        }
      } else if (v==2 && this.areaList.length==0) {
        this.getAreaList(this.searchQueryParm.gameId)
      } else if(v==4) {
        // this.selectGoodsType()
      } else {
        if (!this.searchQueryParm.areaId) {
          this.seletype = 2
          this.getAreaList(this.searchQueryParm.gameId)
        }
      }
    }
    @Emit()
    // 选择游戏
    selectGame(index:number,id:number,nowItem:any) {
      this.isClickOption = true;
      this.seletype = 2;
      // 获取该游戏下的商品类型
      console.log('weqchnjknjk',this.allGoodsTypeList,this.gameList,this.gameListArray);
      if(this.gameListArray.length < 0) {
        return
      }
      console.log('weqchnjknjk1',id,this.gameListArray);
      let tempGoodsTypeList = this.gameListArray.find((v:any) => v.id==id).goodsTypeStr
      console.log('sajkhjkhnlknk',tempGoodsTypeList);
      if(tempGoodsTypeList.includes(',')) {
        console.log('当前游戏的商品类型',this.allGoodsTypeList,'当前游戏的商品类型')
        this.goodsTypeList = this.allGoodsTypeList.filter((v:any) => {
            return tempGoodsTypeList.split(',').includes(v.goodsTypeId+'')
        })
      } else {
        this.goodsTypeList = this.allGoodsTypeList.filter((v:any) => {
            return tempGoodsTypeList.includes(v.goodsTypeId+'')
        })
      }
      console.log(this.goodsTypeList,nowItem,'当前游戏的商品类型');

      let inHistoryGameArrIndex = this.historyGameArr.findIndex((v:any) => v.id ==nowItem.id )   // 这里是开始处理选择游戏的历史记录
      if(inHistoryGameArrIndex > -1) {  // 判断当前点击的游戏是否存在于历史列表中  存在
        if(inHistoryGameArrIndex != 0) {  // 判断是否在第一个位置 不在第一个的话就需要换个位置
          let tempObj = this.historyGameArr[0]
          this.historyGameArr[0] = this.historyGameArr[1]
          this.historyGameArr[1] = tempObj
          window.localStorage.setItem('historyGame',JSON.stringify(this.historyGameArr))
        }
      } else {   //点击的游戏不存在历史列表中
        if(this.historyGameArr.length > 1) {
          this.historyGameArr[1] = JSON.parse(JSON.stringify(this.historyGameArr[0]))
          this.historyGameArr[0] = JSON.parse(JSON.stringify(nowItem))
        } else {
          this.historyGameArr.unshift(JSON.parse(JSON.stringify(nowItem)))
        }
        window.localStorage.setItem('historyGame',JSON.stringify(this.historyGameArr))        // 处理选择游戏的历史记录 结 束
      }

      //拉取大区列表
      let gameIndex = this.gameListArray.findIndex((v:any) => (v.gameId?v.gameId:v.id) == id)

      this.selectGameIndex = gameIndex;
      this.selectGameTxt = this.gameListArray[gameIndex].name;
      //筛选条件赋值
      this.searchQueryParm.gameId=id
      this.selectAreaIndex = -1
      this.selectAreaTxt = ''
      this.selectServerIndex = -1
      this.selectServerTxt = ''
      this.selectGoodsTypeTxt = ''
      this.selectGoodsTypeId = -1
      this.getAreaList(id)
    }
    @Emit()
    //选择大区
    selectArea(index:number,id:number) {
      this.isClickOption = true;
      this.seletype = 3;
      this.selectAreaIndex = index;
      //文本显示赋值
      this.selectAreaTxt = this.areaList[index].specialName;
      //拉取服务器列表
      let serverfilter :any=Object.values(this.areaList).find((v:any)=>v.specialId==id)
      console.log(serverfilter,this.areaList,'===aa=a=a==')
      if (serverfilter) {
        this.serverList=serverfilter.secondOptions
      }
      if (this.serverList.length==0) {
        this.serverList=[]
      }
      //筛选条件赋值
      this.searchQueryParm.areaId=id
      console.log(this.searchQueryParm,'====searchQueryParm=======')
      this.selectServerIndex = -1
      this.selectServerTxt = ''
    }
    @Emit()
    // 选择服务器
    selectServer(index:number,id:number) {
      this.isClickOption = true;
      this.seletype = 4;
      this.selectServerIndex = index;
      //文本显示赋值
      this.selectServerTxt = this.serverList[index].twoSpecialName;
      //筛选条件赋值
      this.searchQueryParm.serverId=id
    }
    @Emit()
    // 搜索
    async jumpUrl(){
      let reg = /^[A-Z][0-9]*$/
      if(this.searchQueryParm.keyWord.length==19 && reg.test(this.searchQueryParm.keyWord)) {
          console.log(this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2),1, 'qwrqqi')

        let tempParam = {goodsSn:this.searchQueryParm.keyWord}
        const {data} = await goodsInfoHttp({params:tempParam})
        console.log(data.code,data,'argh')
        if(data.code == 0 && data.data.goodsSn) {
          console.log(this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2),this.searchQueryParm.keyWord.substr(0,1), 1,'qwrqqi')
          this.searchQueryParm.gameId = this.searchQueryParm.keyWord.substring(this.searchQueryParm.keyWord.length-2)
          if(this.searchQueryParm.gameId[0] == '0') {
            this.searchQueryParm.gameId = this.searchQueryParm.gameId[1]
          }
          if(this.searchQueryParm.keyWord.substr(0,1)=='Z') {
            this.searchQueryParm.gid = 1
          } else if(this.searchQueryParm.keyWord.substr(0,1)=='J'){
            this.searchQueryParm.gid = 2
          }
        }
      }

      // return

      console.log(this.searchQueryParm,window.location.pathname,'dfshkljlk')
      // return
      if (!this.searchQueryParm.gameId) {
        this.seletype=1
        return
      }
      // this.getGoodsListUrl({gameId:this.searchQueryParm.gameId})
      let routeData:any
      if (this.searchQueryParm.areaId && this.searchQueryParm.serverId && this.searchQueryParm.keyWord && this.searchQueryParm.goodsType) {  // 有区 服 关键字 商品类型
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: this.searchQueryParm.goodsType,
            gid: this.searchQueryParm.gameId,
            keyWord: this.searchQueryParm.keyWord,
            areaId: this.searchQueryParm.areaId,
            serverId: this.searchQueryParm.serverId,
            timer: String((new Date()).getTime())
          }
        })
      } else if(this.searchQueryParm.areaId && this.searchQueryParm.serverId && this.searchQueryParm.keyWord) { // 有区 服 关键字
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: '1',
            gid: this.searchQueryParm.gameId,
            keyWord: this.searchQueryParm.keyWord,
            areaId: this.searchQueryParm.areaId,
            serverId: this.searchQueryParm.serverId,
            timer: String((new Date()).getTime())
          }
        })
      } else if(this.searchQueryParm.areaId && this.searchQueryParm.serverId && this.searchQueryParm.goodsType) { // 有区 服 商品类型
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: this.searchQueryParm.goodsType,
            gid: this.searchQueryParm.gameId,
            areaId: this.searchQueryParm.areaId,
            serverId: this.searchQueryParm.serverId,
            timer: String((new Date()).getTime())
          }
        })
      } else if(this.searchQueryParm.areaId && this.searchQueryParm.goodsType && this.searchQueryParm.keyWord) { // 有区 关键字 商品类型
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: this.searchQueryParm.goodsType,
            gid: this.searchQueryParm.gameId,
            keyWord: this.searchQueryParm.keyWord,
            areaId: this.searchQueryParm.areaId,
            timer: String((new Date()).getTime())
          }
        })
      } else if(this.searchQueryParm.areaId && this.searchQueryParm.goodsType) { // 有区 商品类型
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: this.searchQueryParm.goodsType,
            gid: this.searchQueryParm.gameId,
            areaId: this.searchQueryParm.areaId,
            timer: String((new Date()).getTime())
          }
        })
      } else if (this.searchQueryParm.areaId && this.searchQueryParm.keyWord) { // 有区 关键字
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: '1',
            gid: this.searchQueryParm.gameId,
            keyWord: this.searchQueryParm.keyWord,
            areaId: this.searchQueryParm.areaId,
            timer: String((new Date()).getTime())
          }
        })
      } else if (this.searchQueryParm.areaId && this.searchQueryParm.serverId) {    // 有区 服
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: '1',
            gid: this.searchQueryParm.gameId,
            areaId: this.searchQueryParm.areaId,
            serverId: this.searchQueryParm.serverId,
            timer: String((new Date()).getTime())
          }
        })
      } else if(this.searchQueryParm.keyWord && this.searchQueryParm.goodstype) { // 有关键字 商品类型
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: this.searchQueryParm.goodstype,
            gid: this.searchQueryParm.gameId,
            keyWord: this.searchQueryParm.keyWord,
            timer: String((new Date()).getTime())
          }
        })
      } else if (this.searchQueryParm.keyWord) { // 有关键字
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: '1',
            gid: this.searchQueryParm.gameId,
            keyWord: this.searchQueryParm.keyWord,
            timer: String((new Date()).getTime())
          }
        })
      } else if (this.searchQueryParm.areaId) {  // 有区
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: '1',
            gid: this.searchQueryParm.gameId,
            areaId: this.searchQueryParm.areaId,
            timer: String((new Date()).getTime())
          }
        })
      } else if(this.searchQueryParm.goodstype) { // 有商品类型
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: this.searchQueryParm.goodstype,
            gid: this.searchQueryParm.gameId,
            timer: String((new Date()).getTime())
          }
        })
      } else {    // 只有游戏Id
        routeData = this.$router.resolve({
          path: "/wares/",
          query: {
            gt: '1',
            gid: this.searchQueryParm.gameId,
            timer: String((new Date()).getTime())
          }
        })
      }
      if (window.location.pathname != "/wares/") {
        window.open(routeData.href, "_blank");
      } else {
        window.open(routeData.href, "_self");
      }
    }
    @Emit()
    // 关闭弹框
    handleClose(el:any) {
      if (this.isClickOption) {
        this.isClickOption = false;
        return;
      }
      if (this.seletype) this.seletype = 0;
    }
    // 选择游戏类型的处理   端游手游
    selectGameType(v:any) {
      if(this.selectedGameType != v) {
        this.selectedGameType = v
        this.queryParm['type'] = v
        if (this.wordsIndex=='hot') {
          delete this.queryParm['initial']
          if(this.selectedGameType>-1) {
            if(this.selectedGameType == 1) {
              this.gameList=this.hotGamesArray.filter((value:any) => value.gameType==1)
            } else if(this.selectedGameType == 2) {
              this.gameList=this.hotGamesArray.filter((value:any) => value.gameType==2)
            }
          } else {
            this.gameList=this.hotGamesArray
          }
        } else {
          delete this.queryParm.rec
          // this.queryParm['initial']=this.wordsIndex
          this.getGameList(false)
        }
        // this.getGameList(false)
      }
      console.log(this.queryParm,'wenhklnkhnkj');
    }
    onInputBlur() {
      this.isShowSearchGameModal = false
    }
    // getsdmse() {
    //   let arr = [
    //     {a:1, b:2, c:3},
    //     {a:4, b:5, c:6}
    //   ]
    //   let arr2 = []
    //   arr.forEach((v:any) => {
    //     arr2.push({b:v.b, c:v.c})
    //   })

    // }

  }
